.App {
  text-align: center;
}
html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Nunito" !important;
  width: 100vw;
  overflow-x: hidden;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
p ,h1,h2,h3,a, button{
  margin-bottom: 0 !important;
  font-family: 'Nunito' !important;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
/* #myDIV {
  height: 250px;
  width: 250px;
  overflow: auto;
  background: coral;
}

#content {
  margin: 500px;
  height: 800px;
  width: 2000px;
  
  background-color: greenyellow;
} */
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.custom-input {
  background-color: #F2F5FB;
  height: 35px;
  top:4px;
  width:100%;
  font-size: 20px;
}

.custom-input:focus {
  outline: none;
}

.custom-input-2 {
  border-radius: 5px;
  border: 1px #0054A3 solid;
  padding: 4px 12px;
  background-color: #F2F5FB;
  height: 30px;
  width: 100px;
  text-align:center;
}

.custom-input-2:focus {
  outline: none;
}

.MuiAutocomplete-option {
  font-family:'Nunito';
}

.list-teacher::-webkit-scrollbar {
  visibility:hidden; 
  font-family: Nunito
}


:root {
  --primary:  #D2D2D2;
  --secondary: #0054A3;
}

/* Scrollbar styles */

/* Firefox */
.scroll {
  scrollbar-width: thin;
  scrollbar-color: inherit;
}

/* Chrome, Edge, and Safari */
.scroll::-webkit-scrollbar {
  width: 6px;
}

.scroll::-webkit-scrollbar-track {
  background: #F3F3F3;
  border-radius: 20px;
  padding: 2px;
}

.scroll::-webkit-scrollbar-thumb {
  background-color: #219CFF;
  cursor: pointer;
  border-radius: 20px;
}

.ant-picker-time-panel-column::-webkit-scrollbar {
  visibility:hidden;

}
.ant-picker-clear > * {
    vertical-align: top;
    display: none;
}
    .ant-picker-clear:hover > * {
    vertical-align: top;
    display: none;
}

.anticon svg {
    display: none;
}

.ant-picker.ant-picker-borderless{
width:100%
}
.anticon .anticon-close-circle{
display:none,

}
.MuiAutocomplete-noOptions {
  font-family: "Nunito"
}

.last-step-height {
  height: calc(100vh + 200px);
}

.myAnimation {
  animation-name: myAnimation;
  animation-duration: 5s;
}

.react-datepicker__time-container {
  /* border:2px solid red; */
  /* width: 100px; */
  font-size: 16px;
  /* padding: 0.1rem; */
  /* margin-left: 10; */
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: #2196F3;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

#browsers {
  position: absolute;
  background-color: white;
  border: 1px solid blue;
  border-radius: 0 0 5px 5px;
  border-top: none;
  font-family: sans-serif;
  width: 350px;
  padding: 5px;

}

option {
  background-color: white;
  padding: 4px;
  color: blue;
  margin-bottom: 1px;
  font-size: 18px;
  cursor: pointer;
}

.sweat {
  text-decoration: #0054A3;
}
